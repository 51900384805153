import moment from 'moment';
import {
    // getWorkbenchEvents,
    getWorkbenchWorkRequests,
    processWorkbenchEventById,
    getWorkbenchAvailableEvents,
} from '@/api';
import { mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import WorkRequestCard from './Cards/WorkRequest/WorkRequestCard.vue';
import EventCard from './Cards/Event/EventCard.vue';

export default {
    name: 'EventsNotify',
    components: {
        WorkRequestCard,
        EventCard,
    },
    data() {
        return {
            playSoundTimer: null,
            audioSrc: '',
            activeName: '',
            isOpen: false,
            events: [],
            workRequests: [],
            requestInterval: null,
            workbenchRoadEventsCountAnimate: null,
            workbenchWorkRequestsCountAnimate: null,
            isClicked: false,
            eventPage: 0,
            workRequestPage: 0,
        };
    },
    beforeDestroy() {
        clearTimeout(this.requestInterval);
    },
    created() {
        this.getWorkbenchEvents();
        this.getWorkbenchWorkRequests();
        this.requestInterval = setInterval(() => {
            this.getWorkbenchEvents();
            // this.getWorkbenchWorkRequests();
        }, 60000);
        this.createAudio();
    },
    filters: {
        countFormat(val) {
            return val > 9 ? '9' : val;
        },
    },
    computed: {
        eventsFiltered() {
            return this.events.slice(0, this.eventPage);
        },
        workRequestFiltered() {
            return this.workRequests.slice(0, this.workRequestPage);
        },
        ...mapGetters({
            workbenchNotifyVisible: 'workbenchNotifyVisible',
        }),
        workbenchRoadEventsCount() {
            return this.$store.state.workbenchRoadEventsCount;
        },
        workbenchWorkRequestsCount() {
            return this.$store.state.workbenchWorkRequestsCount;
        },
        eventFullAccess() {
            return this.$checkPermission('eputs_its_monitoring_events_done')
                || this.$checkPermission(['eputs_road_works_events_done', 'eputs_road_works_events_full_access']);
        },
        requestFullAccess() {
            return this.$checkPermission('eputs_its_monitoring_work_request_full_access');
        },
    },
    watch: {
        workbenchNotifyVisible(val) {
            this.$set(this, 'isOpen', val);
            this.$set(this, 'activeName', val);
        },
        workbenchRoadEventsCount(val, oldVal) {
            if (val > oldVal) {
                clearTimeout(this.workbenchRoadEventsCountAnimate);
                this.workbenchRoadEventsCountAnimate = setTimeout(() => {
                    this.workbenchRoadEventsCountAnimate = null;
                }, 1000);
            }
        },
        workbenchWorkRequestsCount(val, oldVal) {
            if (val > oldVal) {
                clearTimeout(this.workbenchWorkRequestsCountAnimate);
                this.workbenchWorkRequestsCountAnimate = setTimeout(() => {
                    this.workbenchWorkRequestsCountAnimate = null;
                }, 1000);
            }
        },
    },
    methods: {
        loadWorkRequests() {
            this.workRequestPage += 1;
        },
        loadEvents() {
            this.eventPage += 1;
        },
        toggleNotify(val) {
            this.$store.commit('setWorkbenchNotifyVisible', val);
        },
        handleClick() {
        },
        handleClose() {
            this.$set(this, 'isOpen', false);
            this.$store.commit('setWorkbenchNotifyVisible', false);
        },
        handleWorkRequestsCardClick(e, id) {
            this.navigateToRequest(id);
            // if (e.target.hasAttribute('type') && e.target.getAttribute('type') === 'navigate' && id) {
            // }
        },
        handleEventCardClick(e, eventObj, type) {
            // if (e.target.hasAttribute('type') && eventObj.id) {
                // const type = e.target.getAttribute('type');
            switch (type) {
                case 'process':
                    this.processWorkbenchEventById(eventObj);
                    break;
                case 'navigate_to_new':
                    this.navigateToNewRequest(eventObj.id);
                    break;
                case 'item':
                    this.navigateToEvent(eventObj.id);
                    break;
                default:
            }
            // }
        },
        emitUpdateEventGrid() {
            EventBus.$emit('global-event/event-grid/update');
        },
        async processWorkbenchEventById(obj) {
            this.$set(obj, 'loading', true);
            const { data } = await processWorkbenchEventById(obj.id);
            this.$set(obj, 'loading', false);
            if (data.success) {
                this.$set(obj, 'closed', true);
                this.events = this.events.filter(({ id }) => id !== obj.id);
                setTimeout(() => {
                    this.emitUpdateEventGrid();
                }, 1000);
            }
        },
        async getWorkbenchEvents() {
            try {
                const { data: { data, total } } = await getWorkbenchAvailableEvents({});
                if (total > this.$store.state.workbenchRoadEventsCount) {
                    this.startPlaySoundTimer();
                }
                this.$store.state.workbenchRoadEventsCount = total;
                const parsedData = this.prepareEventsData(data);
                this.$set(this, 'events', parsedData);
            } catch (e) {
                if (e.response && e.response.data && e.response.data.status === 403) {
                    console.warn('No access to notify');
                    clearInterval(this.requestInterval);
                } else console.error(e);
            }
        },
        prepareEventsData(list) {
            const result = list.map((row) => ({
                    ...row,
                    $date_interval: this.getEventDateTimeInterval(row),
                }));
            return result;
        },
        async getWorkbenchWorkRequests() {
            try {
                const { data: { data, total } } = await getWorkbenchWorkRequests({});
                if (total > this.$store.state.workbenchWorkRequestsCount) {
                    this.startPlaySoundTimer();
                }
                this.$store.state.workbenchWorkRequestsCount = total;
                const parsedData = this.prepareWorkRequestData(data);
                this.$set(this, 'workRequests', parsedData);
            } catch (e) {
                if (e.response && e.response.data && e.response.data.status === 403) {
                    console.warn('No access to notify');
                    clearInterval(this.requestInterval);
                } else console.error(e);
            }
        },
        prepareWorkRequestData(list) {
            const result = list.map((row) => ({
                    ...row,
                    tagType: this.getTypeBySla(row.sla),
                    statusIconClass: this.iconClassNameFunction(row.status?.code),
                    formated_date: row.created_at ? moment(row.created_at).format('DD.MM.yyyy HH:mm') : '',
                }));
            return result;
        },
        getEventDateTimeInterval(obj) {
            if (obj.start_date_time && obj.end_date_time) {
                return `${moment(obj.start_date_time).format('DD.MM.YYYY HH:mm')} - ${moment(obj.end_date_time).format('DD.MM.YYYY HH:mm')}`;
            }

            if (obj.start_date_time) return moment(obj.start_date_time).format('DD.MM.YYYY HH:mm');

            return '';
        },
        iconClassNameFunction(status) {
            const type = 'grey';
            // switch (String(status).toLowerCase()) {
            //     case 'opened':
            //     case 'reopened':
            //     case 'created':
            //         type = 'grey';
            //         break;
            //     case 'inprogress':
            //     case 'in_progress':
            //     case 'progress':
            //         type = 'orange';
            //         break;
            //     case 'done':
            //     case 'completed':
            //         type = 'green';
            //         break;
            //     default:
            //         type = 'grey';
            // }
            return `color-${type}`;
        },
        navigateToRequest(id) {
            this.$router.push(`/module/its_monitoring/work_requests/${id}/view`);
            this.handleClose();
        },
        navigateToNewRequest(id) {
            this.$router.push(`/module/its_monitoring/work_requests/new/${id}`);
            this.handleClose();
        },
        navigateToEvent(id) {
            this.$router.push(`/module/its_monitoring/events/${id}/view`);
            this.handleClose();
        },
        createAudio() {
            const context = require.context('@/assets/files/', true, /\.(mp3)$/);
            this.audioSrc = context('./notify-sound.mp3');
        },
        startPlaySoundTimer() {
            if (this.playSoundTimer) {
                clearTimeout(this.playSoundTimer);
            }
            this.playSoundTimer = setTimeout(() => {
                this.playSound();
            }, 1000);
        },
        playSound() {
            const context = {
                audio: new Audio(),
            };
            context.audio.preload = 'auto';
            context.audio.src = this.audioSrc;

            try {
                const promise = context.audio.play();
                promise.catch((e, x) => {
                    const contxt = { ...context };
                    const handleClick = (evnt) => {
                        if (!this.isClicked) {
                            contxt.audio.play();
                            setTimeout(() => {
                                delete contxt.audio;
                            }, 300);
                        }
                        this.isClicked = true;
                        document.removeEventListener('click', handleClick);
                    };
                    document.addEventListener('click', handleClick);
                });
            } catch (e) {
                console.warn(e);
            }

            setTimeout(() => {
                delete context.audio;
            }, 300);
        },
        getTypeBySla(sla) {
            switch (sla) {
                case 'До истечения срока менее 1 часа':
                    return 'warning';
                case 'Просрочено':
                    return 'error';
                default:
                    return 'default';
            }
        },
    },
};
