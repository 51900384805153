import StatusTag from '@/components/StatusTag/StatusTag.vue';

export default {
    name: 'work-request-card',
    components: {
        StatusTag,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        tagType({ value }) {
            return value.tagType;
        },
    },
};
