import Vue from 'vue';
import VueRouter from 'vue-router';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    // base: 'app1',
    mode: 'history',
    routes: [],
});

const vueLifecycles = singleSpaVue({
    Vue,
    async appOptions({ i18n, store }) {
        return {
            router,
            store,
            i18n,
            render: (r) => r(App),
            el: '#header',
        };
    },
});

export const bootstrap = [vueLifecycles.bootstrap];

// export const mount = [vueLifecycles.mount];
export function mount(props) {
    if (props.checkPermission) Vue.prototype.$checkPermission = props.checkPermission;
    return vueLifecycles.mount(props);
}
export const unmount = [vueLifecycles.unmount];
