import i18n from '@/Plugin/Localisation/i18n';
import { setCustomHeaders } from '@/api';

export default {
    data() {
        return {
            currentLocale: i18n.locale,
            locales: [
                { code: 'ru', label: 'рус', flag: 'ru' },
                { code: 'en', label: 'eng', flag: 'gb' },
            ],
        };
    },
    computed: {
        currentLocaleObject({ currentLocale, locales }) {
            const locale = locales.find(({ code }) => code === currentLocale);
            return locale;
        },
        currentLocaleLabel({ currentLocaleObject }) {
            return currentLocaleObject.label;
        },
        currentFlag({ currentLocaleObject }) {
            return currentLocaleObject.flag;
        },
    },
    methods: {
        async changeLocale(locale) {
            if (locale === this.currentLocale) return;
            this.currentLocale = locale;
            this.loading = true;
            try {
                setTimeout(() => {
                    i18n.locale = locale;
                    localStorage.setItem('locale', locale);
                    switch (locale) {
                    case 'ru':
                        setCustomHeaders({ 'Accept-Language': 'ru-RU,ru;q=0.8,en-US;q=0.5,en;q=0.3' }, this.updateDict);
                        break;
                    case 'en':
                        setCustomHeaders({ 'Accept-Language': 'en-US,en;q=0.8,ru-RU;q=0.5,ru;q=0.3' }, this.updateDict);
                        break;
                    default:
                        setCustomHeaders({ 'Accept-Language': 'ru-RU,ru;q=0.8,en-US;q=0.5,en;q=0.3' }, this.updateDict);
                    }
                });
            } catch (e) {
                this.loading = false;
            }
        },
        updateDict() {
            this.$router.go({
                path: this.$route.path,
                force: true,
            });
        },
    },
};
