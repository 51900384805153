import { mapActions } from 'vuex';
import { LOGOUT } from '@/store/auth/auth-actions';
import MainMenu from '../MainMenu/MainMenu.vue';

export default {
    name: 'side-menu',
    components: {
        MainMenu,
    },
    props: {
        dictionaryLink: {
            type: String,
            default: '',
        },
        adminLink: {
            type: String,
            default: '',
        },
        sitCenterLink: {
            type: String,
            default: '',
        },
        links: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            menuVisible: false,
            moduleContainer: document.querySelector('#eputs-module-container'),
        };
    },
    watch: {
        menuVisible: 'global',
    },
    computed: {
        currentPath() {
            return this.$route.path;
        },
    },
    methods: {
        ...mapActions({
            logout: LOGOUT,
        }),
        global(to) {
            if (to) document.getElementById('eputs-module-container').classList.add('sidebar-opened');
            else document.getElementById('eputs-module-container').classList.remove('sidebar-opened');
        },
        toggleMenu() {
            this.menuVisible = !this.menuVisible;
            // if (this.moduleContainer) {
            //     if (!this.menuVisible) this.moduleContainer.classList.remove('side-menu-opened');
            //     if (this.menuVisible) this.moduleContainer.classList.add('side-menu-opened');
            // }
        },
        handleMenuItemClick(path) {
            this.menuVisible = false;
            if (this.$route.path === path) return;
            this.$router.push({ path });
        },
        onRouteEvent() {
            this.menuVisible = false;
        },
    },
    created() {
        window.addEventListener('single-spa:before-routing-event', this.onRouteEvent);
    },
};
