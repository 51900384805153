export default {
    name: 'status-tag',
    props: {
        params: {
            type: Object,
        },
        type: {
            type: String,
            validator(value) {
                return ['success', 'warning', 'error', 'default'].indexOf(value) !== -1;
            },
            default: 'default',
        },
        label: {
            type: String,
            default: '',
        },
    },
    computed: {
        tagType({ type }) {
            return type;
        },
        icon({ type }) {
            switch (type) {
                case 'warning': return 'alert-icon';
                default: return `${type}-icon`;
            }
        },
    },
};
