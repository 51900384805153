import EventBus from '@/eventBus';

export default {
    name: 'MainMenu',
    props: {
        menu: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            pageMenu: null,
            visiblesList: [],
        };
    },
    watch: {
        menuList: {
            deep: true,
            handler(value) {
                if (value && value.length) {
                    this.$set(this, 'visiblesList', value.map(() => ({
                        visible: false,
                    })));
                }
            },
        },
    },
    computed: {
        menuList({ menu, pageMenu }) {
            const rawMenu = pageMenu || menu;
            return rawMenu.map((el) => {
                const el2 = { ...el, menuVisible: false };
                if (el2.subMenu && el2.subMenu.length) {
                    el2.subMenu = el2.subMenu.filter((submenuEl) => !submenuEl.accessRoles || submenuEl.accessRoles(this));
                    if (!el2.subMenu.length) {
                        return null;
                    }
                }
                if (el2.accessRoles && !el2.accessRoles(this)) {
                    return null;
                }
                return el2;
            }).filter((el) => el);
        },
        activePath() {
            return this.$route.path;
        },
    },
    methods: {
        isHttpUrl(href) {
            const regExp = new RegExp('http');
            return regExp.test(href);
        },
        isActive(menuItem) {
            if (!menuItem.subMenu) {
                return this.activePath.includes(menuItem.href);
            }
            const isActive = menuItem.subMenu.filter((sub) => (sub.href.length && this.activePath.includes(sub.href)) || (sub.children && sub.children.some((item) => item.href && this.activePath.includes(item.href)))).length
                || menuItem.subMenu.filter((sub) => sub.href.length && sub.href.includes(this.activePath)).length; // проверяется вхождение друг-друга, так как ссылка меню может содержать query params
            return isActive;
        },
    },
    created() {
        EventBus.$on('set-headerMenu/list', (menu) => {
            this.pageMenu = menu.filter((menuItem) => {
                if (menuItem.accessRoles === undefined) return true;
                if (typeof menuItem.accessRoles === 'function') return menuItem.accessRoles(this);
                return menuItem.accessRoles;
            });
        });
    },
};
