import { mapGetters } from 'vuex'; // mapActions
// import { LOGOUT } from '@/store/auth/auth-actions';
import { USER, USER_ROLES } from '@/store/auth/auth-getters';

export default {
    name: 'header-user',
    computed: {
        ...mapGetters({
            user: USER,
            userRoles: USER_ROLES,
        }),
        userName({ user }) {
            return user ? user.name : '-'; // Аноним
        },
    },
    methods: {
        // ...mapActions({
        //     logout: LOGOUT,
        // }),
        // logout() {
        //     this.$store.dispatch('fast/auth/actions/LOGOUT');
        // },
    },
};
