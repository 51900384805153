export default [
    {
        id: 'transport-permits-module',
        title: 'Модуль выдачи транспортных разрешений',
        iconValue: 'item-check',
        disabled: (ctx) => !ctx.$checkPermission('eputs_transport_permits_access'),
    },
    {
        id: 'traffic-enforcement-module',
        title: 'Модуль администрирования транспортных правонарушений',
        iconValue: 'penalty',
        disabled: (ctx) => !ctx.$checkPermission('eputs_traffic_enforcement_access'),
    },
    {
        id: 'emergency-module',
        title: 'Модуль диспетчерского управления ИТС для ЧС и ВС',
        iconValue: 'bell-emergency',
        disabled: (ctx) => !ctx.$checkPermission('eputs_emergency_access'),
    },
    {
        id: 'its_monitoring',
        title: 'Модуль контроля эффективности ИТС',
        iconValue: 'its-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_its_access'),
    },
    {
        id: 'road-works-module',
        title: 'Модуль управления дорожными работами',
        iconValue: 'roadworks-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_road_works_access'),
    },
    {
        id: 'informing-module',
        title: 'Модуль централизованного информирования участников движения',
        iconValue: 'informing-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_informing_access'),
    },
    {
        id: 'public-transport-module',
        title: 'Модуль управления движением общественного транспорта',
        iconValue: 'public-transport-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_public_transport_access'),
    },
    {
        id: 'io-module',
        title: 'Модуль внутренних и внешних сервисов',
        iconValue: 'io-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_io_access'),
    },
    {
        id: 'parking-module',
        title: 'Модуль конфигурации парковочного пространства',
        iconValue: 'parking-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_parking_access'),
    },
    {
        id: 'modeling-module',
        title: 'Модуль транспортного прогнозирования и моделирования',
        iconValue: 'modeling-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_modeling_access'),
    },
    {
        id: 'ksodd-module',
        title: 'Модуль электронного КСОДД',
        iconValue: 'ksodd-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_ksodd_access'),
    },
    {
        id: 'twin-module',
        title: 'Модуль "Цифровой двойник"',
        iconValue: 'twin-module-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_twin_access'),
    },
    {
        id: 'traffic-camera-map',
        title: 'Подсистема видеонаблюдения, детектирования ДТП и ЧС',
        iconValue: 'tcm-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_tcm_module_access'),
    },
    {
        id: 'its-grade',
        title: 'Подсистема комплексной оценки ИТС',
        iconValue: 'its-grade-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_its_grade_module_access'),
    },
    {
        id: 'pvr-map',
        title: 'Подсистема контроля комплексов ФВФ',
        iconValue: 'pvr-icon',
        disabled: (ctx) => !ctx.$checkPermission('eputs_pvr_module_access'),
    },
    {
        id: 'minister-module',
        title: 'Министр',
        // icon: 'module-icon-15',
        disabled: false,
    },
    {
        id: 'sit-center',
        title: 'Ситуационный центр',
        // icon: 'module-icon-15',
        disabled: false,
    },
    {
        id: 'admin',
        title: 'Управление пользователями и доступом',
    },
    {
        id: 'dictionary',
        title: 'Справочная информация',
    },
];
