import { mapGetters, mapState, mapActions } from 'vuex';
import { getModules } from '@/api';
import { LOGOUT } from '@/store/auth/auth-actions';
import modules from '@/modules';
import EventsNotify from '../components/EventsNotify/EventsNotify.vue';
import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher.vue';
import HeaderUser from '../components/HeaderUser/HeaderUser.vue';
import SideMenu from '../components/SideMenu/SideMenu.vue';
import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs.vue';

export default {
    name: 'AppHeader',
    components: {
        HeaderUser,
        SideMenu,
        EventsNotify,
        LanguageSwitcher,
        BreadCrumbs,
    },
    mounted() {
        window.addEventListener('current-module-id-changed', (event) => {
            this.moduleId = event.detail.storage;
        });
    },
    data() {
        return {
            modules,
            pageTitle: 'Мониторинг и контроль транспортного комплекса',
            links: null,
            moduleId: null,
            disabled: [1, 2],
            workbenchRoadEventsCountAnimate: null,
            workbenchWorkRequestsCountAnimate: null,
            availableModules: [],
        };
    },
    filters: {
        countFormat(val) {
            return val > 9 ? '9+' : val;
        },
    },
    computed: {
        ...mapState({
            modulesLinks: 'modulesLinks',
            selectedAgglomeration: 'selectedAgglomeration',
        }),
        ...mapGetters({
            agglomirationName: 'agglomirationName',
            agglomerationList: 'agglomerationList',
        }),
        theme: {
            set(val) {
                this.$store.commit('setTheme', val);
                this.$store.commit('setMapStyles', val);
                this.startLoader();
            },
            get() {
                return this.$store.state.theme;
            },
        },
        workbenchRoadEventsCount() {
            return this.$store.state.workbenchRoadEventsCount;
        },
        workbenchWorkRequestsCount() {
            return this.$store.state.workbenchWorkRequestsCount;
        },
        hasCrumbs() {
            return !!this.$route.params.moduleId || !!this.$route.meta.moduleId || !!this.moduleId;
        },
        modulesPath() {
            return `/module/${this.moduleId}`;
        },
        activeModule() {
            return this.modules.find((el) => this.$route.params.moduleId === el.id || this.$route.meta.moduleId === el.id || this.moduleId === el.id) || {};
        },
        modulesList() {
            this.$store.commit('setModulesLinks', this);
            return this.modules.filter((item) => this.modulesLinks[item.id]).map((item) => {
                const val = { ...item, href: this.modulesLinks[item.id] };
                return val;
            });
        },
        isItsModule({ moduleId }) {
            return moduleId && ['its_monitoring', 'admin'].includes(moduleId);
        },
        adminLink({ availableModules, isItsModule }) {
            const isAdmin = availableModules.find(({ name }) => name === 'admin') && this.$checkPermission('eputs_admin_user');
            return isAdmin && isItsModule ? '/module/admin' : null;
        },
        dictionaryLink({ availableModules }) {
            const isDictionary = availableModules.find(({ name }) => name === 'dictionary') && this.$checkPermission('eputs_dictionary_user');
            return isDictionary ? '/module/dictionary' : null;
        },
        sitCenterLink({ availableModules }) {
            const isSitCenter = availableModules.find(({ name }) => name === 'sit-center') && this.$checkPermission('eputs_sit_center_access');
            return isSitCenter ? '/module/sit-center/map' : null;
        },
        menuWidth() {
            if (this.agglomerationList.length <= 1) {
                return 0;
            }
            let nameMaxLength = 0;
            this.agglomerationList.forEach(({ name }) => {
                if (name.length > nameMaxLength) {
                    nameMaxLength = name.length;
                }
            });
            return nameMaxLength * 10.5;
        },
        sideMenuLinks({
            adminLink, dictionaryLink, sitCenterLink,
        }) {
            return {
                adminLink,
                dictionaryLink,
                sitCenterLink,
            };
            // return links.filter((link) => !!link);
        },
        isMinister({ moduleId }) {
            return moduleId === 'minister';
        },
    },
    methods: {
        ...mapActions({
            setAgglomeration: 'setAgglomeration',
            logout: LOGOUT,
        }),
        toggleNotify(val) {
            this.$store.commit('setWorkbenchNotifyVisible', val);
        },
        async getAvailableModules() {
            try {
                this.loading = true;
                const { data } = await getModules();
                this.$set(this, 'availableModules', data);
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },
        startLoader() {
            const loading = this.$loading({
                lock: true,
                text: 'Производится перенастройки темы в системе, этой займет некоторое время, ожидайте окончание загрузки.',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.9)',
            });
            setTimeout(() => {
                loading.close();
            }, 2000);
        },
    },
    watch: {
        workbenchRoadEventsCount(val, oldVal) {
            if (val > oldVal) {
                clearTimeout(this.workbenchRoadEventsCountAnimate);
                this.workbenchRoadEventsCountAnimate = setTimeout(() => {
                    this.workbenchRoadEventsCountAnimate = null;
                }, 1000);
            }
        },
        workbenchWorkRequestsCount(val, oldVal) {
            if (val > oldVal) {
                clearTimeout(this.workbenchWorkRequestsCountAnimate);
                this.workbenchWorkRequestsCountAnimate = setTimeout(() => {
                    this.workbenchWorkRequestsCountAnimate = null;
                }, 1000);
            }
        },
    },
    async created() {
        await this.getAvailableModules();
        this.eventBusOn('setTitle', (payload) => {
            this.pageTitle = payload;
        });
        this.eventBusOn('setLinks', (payload) => {
            this.links = payload;
        });
        this.eventBusOn('set-header/moduleId', (payload) => {
            this.moduleId = payload;
        });
        this.$store.commit('setModulesLinks', this);
        window.headerPage = this;
    },
};
