export default {
    name: 'bread-crumbs',
    props: {
        activeModule: {
            type: Object,
        },
    },
    computed: {
        title({ activeModule }) {
            return activeModule.title || 'Модуль';
        },
        link({ activeModule }) {
            console.log(activeModule);
            return `/module/${activeModule.id}` || '';
        },
    },
};
